export default {
    SET_QUIZ_TYPE(state, payload) {
        state.quizType = payload
    },
    SET_CURRENT_QUIZ(state, payload) {
        state.currentQuiz = payload
    },
    SET_CURRENT_QUIZ_ANSWERER(state, payload) {
        state.currentQuizAnswerer = payload[0]
    },
}
