import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'coupe-de-france-du-potager',
      component: () => import('@/views/cfp/CFPHome.vue'),
      meta: {
        layout: 'vertical',
        rule: 'editor'
      }
    },
    {
      path: '/evaluation',
      name: 'coupe-de-france-du-potager-evaluation',
      component: () => import('@/views/cfp/CFPEvaluation.vue'),
      meta: {
        layout: 'vertical',
        rule: 'editor',
        requiresAuth: true
      }
    },
    {
      path: '/editions-precedente/2021',
      name: 'coupe-de-france-du-potager-evaluation-2021',
      component: () => import('@/views/cfp/CFPPastYear.vue'),
      meta: {
        layout: 'vertical',
        rule: 'editor',
        requiresAuth: true
      }
    },
    {
      path: '/editions-precedente/2022',
      name: 'coupe-de-france-du-potager-evaluation-2022',
      component: () => import('@/views/cfp/CFPPastYear.vue'),
      meta: {
        layout: 'vertical',
        rule: 'editor',
        requiresAuth: true
      }
    },
    {
      path: '/editions-precedente/2023',
      name: 'coupe-de-france-du-potager-evaluation-2023',
      component: () => import('@/views/cfp/CFPPastYear.vue'),
      meta: {
        layout: 'vertical',
        rule: 'editor',
        requiresAuth: true,
      }
    },
    {
      path: '/compte-utilisateur',
      name: 'user-account',
      component: () => import('@/views/accounts/UserAccount.vue'),
      meta: {
        layout: 'vertical',
        rule: 'editor',
        requiresAuth: true
      }
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/views/login/ChangePassword.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/login/Register.vue'),
      meta: {
        layout: 'full',
        noLoggedAccess: true
      }
    },
    {
      path: '/verify-user',
      name: 'verify-user',
      component: () => import('@/views/login/VerifyUser.vue'),
      meta: {
        layout: 'full',
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/login/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      }
    },
    {
      path: '/pages/reset-password',
      name: 'reset-password',
      component: () => import('@/views/login/ResetPassword.vue'),
      meta: {
        layout: 'full',
      }
    },
    {
      path: "/mentions-legales",
      name: "legal-notice",
      component: () => import("@/views/gdpr/GdprDocPage.vue"),
      meta: {
        layout: 'vertical',
      }
    },
    {
      path: "/conditions-utilisation",
      name: "general-conditions",
      component: () => import("@/views/gdpr/GdprDocPage.vue"),
      meta: {
        layout: 'vertical',
      }
    },
    {
      path: "/charte-protection-donnees",
      name: "gdpr-charter",
      component: () => import("@/views/gdpr/GdprDocPage.vue"),
      meta: {
        layout: 'vertical',
      }
    },
    {
      path: "/politique-cookies",
      name: "cookie-policy",
      component: () => import("@/views/gdpr/GdprDocPage.vue"),
      meta: {
        layout: 'vertical',
      }
    },
    {
      path: "/signature-doc-rgpd",
      name: "gdpr-doc-signature",
      component: () => import("@/views/gdpr/GdprDocSignature.vue"),
      meta: {
        layout: 'vertical',
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    if (from.name && to.name !== 'login-required') {
      router.app.$loading(true)
    }
    if (router.app.$options.store && to.name !== 'update-required' && to.name !== 'login-required') {
      router.app.$options.store.dispatch('account/fetchProfile')
          .then(() => {
            router.app.$i18n.locale = router.app.$options.store.state.account.user.language
            router.app.$options.store.dispatch('gdpr/fetchGdprLastDocsNotSigned')
              .then(() => {
                if (router.app.$options.store.state.gdpr.gdprLastDocsNotSigned.length > 0 &&
                    to.name !== "gdpr-doc-signature") {
                  router.app.$notify({
                    time: 4000,
                    title: 'Nouveaux documents',
                    color: 'primary',
                    text: "Une nouvelle version des documents d'information nécessite votre approbation",
                  })
                  if (router.currentRoute.name !== "gdpr-doc-signature") {
                    next({name: 'gdpr-doc-signature'})
                  }
                  router.app.$loading(false)
                } else {
                  // Must be logged ?
                  if (to.matched.some(record => record.meta.noLoggedAccess)) {
                    next({name: 'coupe-de-france-du-potager'})
                  }
                  // Ok
                  else {
                    next()
                  }
                }
              })
          })
          .catch(error => {
            router.app.$loading(false)
            if (to.matched.some(record => record.meta.requiresAuth) &&
                error.response && error.response.statusText === "Unauthorized") {
              router.app.$notify({
                time: 4000,
                title: this.$t('Error'),
                color: 'danger',
                text: 'La page ' + to.name + " nécessite d'être authentifié",
              })
              next({name: 'login'})
            } else {
              next()
            }
          })
    } else {
      router.app.$loading(false)
      next()
    }
  } else {
    next()
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  if (from.name === null) {
    setTimeout(() => {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
      }
    }, 1000);
  }

  router.app.$loading(false)
})
window.getVueRouter = () => {return router}
export default router
