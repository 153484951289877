import axios from "@/libs/axios.js"

export default {
    fetchQuizType({commit}, params) {
        return new Promise((resolve, reject) => {
            axios.get('/cdf_quizzes_type/', {params: params})
                .then(response => {
                    commit('SET_QUIZ_TYPE', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getQuiz(context, params) {
        return new Promise((resolve, reject) => {
            axios.get('/cdf_quizzes/', {params: params})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    retrieveQuiz({commit}, params) {
        return new Promise((resolve, reject) => {
            params["details"] = true
            axios.get('/cdf_quizzes/' + params.id.toString(), {params: params})
                .then(response => {
                    commit('SET_CURRENT_QUIZ', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getQuizAnswerer({commit}, params) {
        return new Promise((resolve, reject) => {
            axios.get('/cdf_answers/', {params: params})
                .then(response => {
                    commit('SET_CURRENT_QUIZ_ANSWERER', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createQuizAnswerer(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/cdf_answers/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createQuizResponse(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/cdf_responses/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteQuizResponse(context, params) {
        return new Promise((resolve, reject) => {
            axios.delete('/cdf_responses/' + params["responseId"].toString() + '/')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateQuizResponse(context, payload) {
        return new Promise((resolve, reject) => {
            axios.patch('/cdf_responses/' + payload["responseId"].toString() + '/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    askQuizRetrieval() {
        return new Promise((resolve, reject) => {
            axios.post('/previous_editions/', {})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    removeJustification(context, params) {
        return new Promise((resolve, reject) => {
            axios.delete('uploads/' + params.id.toString() + '/')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
