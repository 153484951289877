import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleAccount from './account/moduleAccount.js'
import moduleCFP from './cfp/moduleCFP.js'
import moduleGDPR from "@/store/gdpr/moduleGDPR";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    cfp: moduleCFP,
    account: moduleAccount,
    gdpr: moduleGDPR
  },
  mutations: {
    DELETE_ALL_USER_DATA(state) {
      state.account.user = null
    },
  },
  strict: process.env.DEV,
})
