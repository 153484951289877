import axios from "@/libs/axios.js"

export default {
    fetchGdprLastDocs({commit}, params) {
        return new Promise((resolve, reject) => {
            axios.get('/gdpr_last_docs/', {params: params})
                .then(response => {
                    commit('SET_GDPR_LAST_DOCS', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchGdprLastDocsNotSigned({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/gdpr_last_docs_not_signed/', payload)
                .then(response => {
                    commit('SET_GDPR_LAST_DOCS_NOT_SIGNED', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    signGdprDoc(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/gdpr_signature/', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
