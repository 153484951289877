import axios from "@/libs/axios.js"

export default {
    login({dispatch}, credentials) {
        return new Promise((resolve, reject) => {
            axios.post("/accounts/login/", credentials)
                .then(() => {
                    dispatch("fetchData")
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            resolve(error)
                        })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchProfile({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("/accounts/profile/")
                .then(response => {
                    commit('UPDATE_USER_INFO', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchData({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("/accounts/profile/")
                .then(response => {
                    let user = response.data
                    commit('UPDATE_USER_INFO', user)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    logout(context) {
        return new Promise((resolve, reject) => {
            axios.post("/accounts/logout/", {revoke_token: true})
                .then(() => {
                    context.commit('appConfig/UPDATE_SKIN', 'light', {root:true})
                    context.commit("UPDATE_USER_INFO", null)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    register(context, data) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/register/", data, {timeout: 10000})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    register_confirmation({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/verify-registration/", payload)
                .then(() => {
                    dispatch("fetchData")
                        .then((response) => {
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    deleteAccount({commit}, payload) {
        payload.revoke_token = true
        return new Promise((resolve, reject) => {
            axios.post("/accounts/delete-account/", payload)
                .then(() => {
                    commit("DELETE_ALL_USER_DATA", null, {root: true})
                    resolve()
                })
                .catch((error) => {
                    if (error.response) {
                        if (Array.isArray(error.response.data) &&
                            error.response.data.includes("Login or password invalid.")) {
                            reject("passwordError")
                        }
                    }
                    reject("unknownError")
                })
        })
    },

    updateProfile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.patch("accounts/profile/", data)
                .then((response) => {
                    commit("UPDATE_USER_INFO", response.data)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    send_reset_password({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/send-reset-password-link/", payload)
                .then(() => {
                    dispatch("is_active", {email: payload.login})
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    reset_password(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/reset-password/", payload)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    change_password(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/change-password/", payload)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    resend_validation_email({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("accounts/resend-email/", payload)
                .then(() => {
                    commit('UPDATE_ACTIVE_STATUS', false)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    is_active({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.post("/accounts/is-active/", payload)
                .then(response => {
                    commit('UPDATE_ACTIVE_STATUS', !response.data.active)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
