import Vue from 'vue'

// axios
import axios from 'axios'

window.getApiHeaders = function () {
    let headers = {}

    // Add CSRF Token
    headers['X-CSRFToken'] = window.$cookies.get('csrftoken')

    return headers
}

const axiosIns = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 5000,
    withCredentials: true,
    transformRequest: [function (data, headers) {

        for (const [key, value] of Object.entries(window.getApiHeaders())) {
            headers[key] = value
        }

        return JSON.stringify(data)
    }]
})
axiosIns.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status == 403 && error.response.data.detail.toLowerCase().includes("csrf")) {

        if (window.getRoute().path != "/pages/relogin" && window.getRoute().path != "/pages/login") {
            window.getVueRouter().push({name: "login-required", hash: "/#disconnect"})
        }
    }
    if (error.response && error.response.config.url == "/accounts/logout/") {
        window.getVueRouter().push({name: "login"})
    }
    return Promise.reject(error);
})

Vue.prototype.$http = axiosIns

export default axiosIns
