<template>
  <div v-if="gdprDoc">
    <h1 class="mb-1 text-center text-secondary">{{ gdprDoc.title }}</h1>

    <p class="text-center font-italic">
      Version {{ gdprDoc.version }} en date du
      {{ $parseDate(gdprDoc.date_created) }}
    </p>

    <p class="text-justify" v-html="gdprDoc.content"></p>

    <div
        v-if="gdprDoc.doc_type === 1"
        class="mt-4 d-flex justify-content-center flex-wrap w-100"
    >
      <a
          @click="$router.push({ name: 'gdpr-charter' })"
          class="btn btn-primary m-1"
      >
        Charte de Protection des Données Personelles
      </a>
      <a
          @click="$router.push({ name: 'cookie-policy' })"
          class="btn btn-primary m-1"
      >
        Politique de Cookies et Traçeurs
      </a>
      <a
          @click="$router.push({ name: 'general-conditions' })"
          class="btn btn-primary m-1"
      >
        Conditions Générales d'Utilisation
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GdprDocViewer',
  props: {
    doc_type: String,
  },
  data() {
    return {
      gdprDoc : null,
    }
  },
  mounted() {
    this.fetchGdprLastDocs();
  },
  methods: {
    fetchGdprLastDocs() {
      const name = this.doc_type ? this.doc_type : this.$router.currentRoute.name;
      const doc_type = this.$store.state.gdpr.gdprDocTypes.find((type) => type.name === name).choice;
      const lang = this.$i18n.locale
      this.$store.dispatch("gdpr/fetchGdprLastDocs", { doc_type: doc_type, lang: lang })
          .then(() => {
            this.gdprDoc = this.$store.state.gdpr.gdprLastDocs.filter(e=>(e.doc_type===doc_type))[0]
          })
          .catch(() => {
            this.$notify({
              text: "La récupération des dernières versions des documents RGPD a échoué",
              title: this.$t('Error'),
              color: "error",
              duration: 3000,
            });
          })
    }
  }
}
</script>
