import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const notify = function (props) {
    this.$toast(
        {
            component: ToastificationContent,
            props: {
                title: props.title,
                icon: props.icon ? props.icon : "",
                text: props.text,
                variant: props.color
            },
        },
        {
            position: "bottom-right",
            timeout: props.time ? props.time : 4000
        })
}
const loading = function (value, message) {
    this.$store.commit('app/UPDATE_MESSAGE', value ? message : null)
    this.$store.commit('app/UPDATE_LOAD', value)
}
export const openLinkJaya = function (url) {
    window.open(
        url,
            '_blank'
    );

}

const parseDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
};

const parsePasswordErrors = (passwordError) => {
    if (passwordError.includes("This password is too common.")) {
        return "tooCommonPassword"
    } else if (passwordError.includes("The password is too similar to the username.")) {
        return "tooSimilar"
    } else if (passwordError.includes("This password is entirely numeric.")) {
        return "numeric"
    } else if (passwordError.includes("This password is too short.")) {
        return "tooShort"
    }
    return "badPassword"
}

Vue.prototype.$loading = loading
Vue.prototype.$notify = notify
Vue.prototype.$openLinkJaya = openLinkJaya
Vue.prototype.$parseDate = parseDate
Vue.prototype.$parsePasswordErrors = parsePasswordErrors