export default {
  quizTypeName: [
    {
      type: 'SC',
      name: {fr: 'Établissements scolaires (2021 et 2022)'},
    },
    {
      type: 'CO',
      name: {fr: 'Entreprises (2022)'},
    },
    {
      type: 'SP',
      name: {fr: 'Clubs de sport'},
    },
    {
      type: 'AS',
      name: {fr: 'Association (2022)'},
    },
    {
      type: 'ST',
      name: {fr: 'Etudiants'},
    },
    {
      type: 'YS',
      name: {fr: 'Structures jeunesses (2022)'},
    },
    {
      type: 'YO',
      name: {fr: 'Établissements scolaires et structures jeunesses', en: 'World', 'es': 'Mundo'},
    },
    {
      type: 'PR',
      name: {fr: 'Organismes professionnels et associatifs'},
    },
    {
      type: 'IN',
      name: {fr: 'Particuliers (seul ou à plusieurs)'},
    },
    {
      type: 'TC',
      name: {fr: 'Tennis'},
    },
  ],
  categoriesImages: [
    {
      title: 'PRESENTEZ VOUS',
      imageName: 'PresentezVous',
    },
    {
      title: 'VOTRE ÉQUIPE',
      imageName: 'VotreEquipe',
    },
    {
      title: 'LA BIODIVERSITÉ C’EST CARRÉ',
      imageName: 'LaBiodiversiteCestCarre',
    },
    {
      title: 'LA BIODIVERSITÉ C’EST CARRÉ !',
      imageName: 'LaBiodiversiteCestCarre',
    },
    {
      title: 'CA NOUS NOURRIT',
      imageName: 'CaNousNourrit',
    },
    {
      title: 'MON BO POTAGER',
      imageName: 'MonBoPotager',
    },
    {
      title: 'ON EST PRÊTS',
      imageName: 'OnEstPrets',
    },
    {
      title: 'ENSEMBLE ON VA PLUS LOIN',
      imageName: 'EnsembleOnVaPlusLoin',
    },
    {
      title: 'J’APPRENDS JE PARTAGE',
      imageName: 'JApprendsJePartage',
    },
    {
      title: 'J\'APPRENDS, JE PARTAGE',
      imageName: 'JApprendsJePartage',
    },
    {
      title: 'QUELLE DEBROUILLARDISE',
      imageName: 'QuelleDebrouillardise',
    },
    {
      title: 'MINI POUSSES',
      imageName: 'MiniPousses',
    },
    {
      title: 'BONUS BONUS',
      imageName: 'BonusBonus',
    }
  ],
  quizType: null,
  currentQuiz: null,
  currentQuizAnswerer: null,
}
